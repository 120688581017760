/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Provides useful utility
 * functions that do not require being called as hooks.
 */

import { isString } from "lodash";
import AES from "crypto-js/aes";
import { enc, mode, pad } from "crypto-js";
import {
  ARRAY_TYPE_FIELDS,
  BOOLEAN_TYPE_FIELDS,
  FLOAT_TYPE_FIELDS,
  INT_TYPE_FIELDS,
} from "./constants";

import { client } from "../apollo/client";
import { LOGOUT_MUTATION } from "../mutations/user";
import { toast } from "react-toastify";
import {
  and,
  equals,
  head,
  includes,
  isEmpty,
  isNil,
  not,
  or,
  prop,
  propEq,
  take,
} from "ramda";
import dayjs from "dayjs";

function timeDifference(current, previous) {
  const milliSecondsPerMinute = 60 * 1000;
  const milliSecondsPerHour = milliSecondsPerMinute * 60;
  const milliSecondsPerDay = milliSecondsPerHour * 24;
  const milliSecondsPerMonth = milliSecondsPerDay * 30;
  const milliSecondsPerYear = milliSecondsPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < milliSecondsPerMinute / 3) {
    return "сега";
  }

  if (elapsed < milliSecondsPerMinute) {
    return "преди около минута";
  } else if (elapsed < milliSecondsPerHour) {
    return "преди " + Math.round(elapsed / milliSecondsPerMinute) + " мин";
  } else if (elapsed < milliSecondsPerDay) {
    return "преди " + Math.round(elapsed / milliSecondsPerHour) + " часа";
  } else if (elapsed < milliSecondsPerMonth) {
    return `преди ${Math.round(elapsed / milliSecondsPerDay)}
      ${Math.round(elapsed / milliSecondsPerDay) > 1 ? " дни" : " ден"}`;
  } else if (elapsed < milliSecondsPerYear) {
    return "преди " + Math.round(elapsed / milliSecondsPerMonth) + " мес.";
  } else {
    return "преди" + Math.round(elapsed / milliSecondsPerYear) + " год.";
  }
}

export function timeDifferenceForDate(date) {
  const now = new Date().getTime();
  const updated = new Date(date).getTime();
  return timeDifference(now, updated);
}

export function isChatRoomActive(participants, userStatuses, userId) {
  let online = false;
  for (let participant of participants) {
    if (
      and(
        not(equals(participant, userId)),
        userStatuses.some((status) =>
          and(equals(status.userId, participant), status.active)
        )
      )
    ) {
      online = true;
    }
  }
  return online;
}

export function calculateUnreadMessages(chatrooms, user) {
  let unread = {};

  chatrooms.forEach((chatroom) => {
    let lrm = chatroom.lastReadDate ? new Date(chatroom.lastReadDate) : null;

    if (chatroom.messages && lrm) {
      chatroom.messages.forEach((message) => {
        if (
          new Date(message.createdAt) > lrm &&
          !equals(prop("_id", user), message.senderId)
        ) {
          const chatroomId = prop("_id", chatroom);
          if (!unread[chatroomId]) unread[chatroomId] = 1;
          else unread[chatroomId] += 1;
        }
      });
    }
  });

  return unread;
}

export function getAge(dateString) {
  let today = new Date();
  let birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function calculateDaysToBday(bdayString) {
  const bdayDate = dayjs(new Date(bdayString).toISOString().split("T")[0]);
  const now = dayjs(new Date());
  const hoursTillBday = bdayDate
    .set("year", new Date().getFullYear())
    .diff(now, "hours");

  const dateOfBday = bdayDate.get("date");
  const dateNow = now.get("date");

  const isToday = hoursTillBday <= 24 && dateOfBday === dateNow;

  const daysTillBday = Math.ceil(hoursTillBday / 24);

  return isToday ? 0 : daysTillBday;
}

export function listingsFromDBToMST(listings) {
  return [
    ...listings.map((listing) => ({
      ...listing,
      currentPrice: {
        ...listing?.currentPrice,
        date: new Date(listing?.currentPrice?.date),
      },
      prices: [
        ...(listing?.prices ?? []).map((price) => ({
          ...price,
          date: new Date(price?.date),
        })),
      ],
      createdAt: new Date(listing?.createdAt),
      updatedAt: new Date(listing?.updatedAt),
      broker: listing.broker
        ? {
            _id: listing.broker?._id,
            email: listing.broker?.email,
            phone: listing.broker?.phone,
          }
        : "",
      proposedByUser: listing.proposedByUser ? listing.proposedByUser?._id : "",
    })),
  ];
}

export const capitalize = (phrase) => {
  let capitalized = "";
  let words = phrase.split(" ");

  words.forEach((word, index) => {
    word = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized = capitalized.concat(
      index === words.length - 1 ? word : word + " "
    );
  });
  return capitalized;
};

export const extractLegalListings = (listings, user, forReview) => {
  // Either for review or approved where we know the user is broker
  if (!forReview) {
    return listings.filter(
      (listing) =>
        (listing.broker &&
          listing.broker._id.toString() === user._id.toString()) ||
        user.privileges === "admin"
    );
  } else {
    // For Review
    if (forReview) {
      return listings.filter(
        (listing) =>
          listing.status === "for review" &&
          (!listing.broker ||
            listing.broker._id.toString() === user._id.toString() ||
            user.privileges === "admin")
      );
    }
    // Already approved
    else {
      return listings.filter(
        (listing) =>
          listing.status !== "for review" &&
          (!listing.broker ||
            listing.broker._id.toString() === user._id.toString() ||
            user.privileges === "admin")
      );
    }
  }
};

export const openTransferPopup = (
  adminStoreInstance,
  brokerIdToTransferFrom,
  deleting,
  transferPopupRemoveFromListCb,
  transferPopupCouldAbortChange = false
) => {
  adminStoreInstance.setTransferPopupOpen(true);
  adminStoreInstance.setTransferPopupDeleting(
    deleting,
    transferPopupRemoveFromListCb
  );
  adminStoreInstance.setBrokerIdToTransferFrom(brokerIdToTransferFrom);
  adminStoreInstance.setTransferPopupCouldAbortChange(
    transferPopupCouldAbortChange
  );
};

export const logout = () => {
  localStorage.clear();
};

export const filtersFromQueryString = (location) => {
  location.search = (decodeURI(location.search || "") || "")?.replaceAll(
    "%2C",
    ","
  );

  const listingType =
    decodeURIComponent(location.pathname).includes("продажби") ||
    decodeURIComponent(location.pathname).includes("sale")
      ? "sale"
      : decodeURIComponent(location.pathname).includes("наеми") ||
        decodeURIComponent(location.pathname).includes("rent")
      ? "rent"
      : "rent";

  let filters = {
    listingType,
  };

  location.search
    .replace("?", "")
    .split("&")
    .forEach((filter) => {
      const filterName = filter.split("=")[0];
      filters[filterName] = filter.split("=")[1];
    });

  filters = parseFilters(filters, true);

  return filters;
};

function stringFiltersToArrays(filters) {
  // Array-type fields
  for (let field in filters) {
    if (isString(filters[field]) && ARRAY_TYPE_FIELDS.includes(field))
      filters[field] =
        filters[field].trim().length > 0
          ? filters[field].trim().split(",")
          : [];
  }

  return filters;
}

function stringifyArrayFields(filters) {
  for (let field in filters) {
    if (Array.isArray(filters[field])) {
      let newVal = "";
      filters[field].forEach((item, index) => {
        newVal = newVal.concat(item);
        if (index < filters[field].length - 1) {
          newVal = newVal.concat(",");
        }
      });
      filters[field] = newVal;
    }
  }
  return filters;
}

export const parseFilters = (filters, arrayify, stringify) => {
  if (arrayify) filters = stringFiltersToArrays(filters);

  if (stringify) {
    filters = stringifyArrayFields(filters);
  }

  for (let field in filters) {
    if (
      BOOLEAN_TYPE_FIELDS.includes(field) &&
      typeof filters[field] === "string"
    ) {
      filters[field] = filters[field] === "true";
    }

    if (FLOAT_TYPE_FIELDS.includes(field)) {
      filters[field] = parseFloat(filters[field]);
    }
    if (INT_TYPE_FIELDS.includes(field)) {
      filters[field] = parseInt(filters[field], 10);
    }
  }
  for (let key of Object.keys(filters)) {
    if (isEmpty(key) || or(isNil(filters[key]), isEmpty(filters[key])))
      delete filters[key];
  }
  return filters;
};
export const checkFormStateValidity = (
  requiredFields,
  formState,
  invalidState
) => {
  return Object.keys(invalidState).some((key) => {
    return requiredFields[key]
      ? !formState[key].trim() || invalidState[key]
      : formState[key] && invalidState[key];
  });
};

export const stringArrAndOptionsArrAreDifferent = (stringArr, optionsArr) => {
  if (stringArr && optionsArr) {
    stringArr = stringArr.sort();
    optionsArr = optionsArr.map((option) => option.value).sort();

    if (stringArr.length && optionsArr.length) {
      return JSON.stringify(stringArr) !== JSON.stringify(optionsArr);
    }
  }
  return true;
};

export const objectsHaveSameKeys = (obj1, obj2) => {
  let keys1 = Object.keys(obj1).sort();
  let keys2 = Object.keys(obj2).sort();
  return JSON.stringify(keys1) === JSON.stringify(keys2);
};

// For the sake of readability this is kept less concise
function checkFloorIsSuitable(
  noFirstFloor,
  noLastFloor,
  onlyFirstFloor,
  onlyLastFloor,
  listing
) {
  if (noFirstFloor) {
    if ([0, 1].includes(listing.floor)) return false;
  }

  if (noLastFloor) {
    if (listing.floor === listing.floors) return false;
  }

  if (onlyFirstFloor) {
    if (![0, 1].includes(listing.floor)) return false;
  }

  if (onlyLastFloor) {
    if (!listing.floor === listing.floors) return false;
  }

  return true;
}

export const checkListingMatchesFilters = (listing, filters) => {
  // Cast non-string fields to make them operable

  filters = parseFilters(filters);

  let {
    propertyTypes,
    propertySubTypes,
    minPrice,
    maxPrice,
    minArea,
    maxArea,
    minYardArea,
    maxYardArea,
    minFloor,
    maxFloor,
    minFloors,
    maxFloors,
    noFirstFloor,
    noLastFloor,
    onlyFirstFloor,
    onlyLastFloor,
    currency,
    buildingMaterials,
    minYearBuilt,
    maxYearBuilt,
    listingType,
    regions,
    locations,
    subLocations,
    countries,
    keywords,
    status,
    specifics,
    details,
  } = filters;

  if (listingType && listingType !== listing.listingType) return false;

  if (status && status !== listing.status) return false;
  if (propertyTypes && propertyTypes.length) {
    if (!propertyTypes.includes(listing.propertyType)) return false;
  }
  if (propertySubTypes && propertySubTypes.length) {
    if (!listing.propertySubType) return false;
    if (!propertySubTypes.includes(listing.propertySubType)) return false;
  }

  // Money
  if (currency) {
    if (listing.currentPrice.currency !== currency) return false;
  }
  if (minPrice) {
    if (minPrice > listing.currentPrice.price) return false;
  }
  if (maxPrice) {
    if (listing.currentPrice.price > maxPrice) return false;
  }

  // Area
  if (minArea) {
    if (listing.area < minArea) return false;
  }
  if (maxArea) {
    if (listing.area > maxArea) return false;
  }
  if (minYardArea) {
    if (!listing.yardArea) return false;
    if (listing.yardArea < minYardArea) return false;
  }
  if (maxYardArea) {
    if (!listing.yardArea) return false;
    if (listing.yardArea > maxYardArea) return false;
  }

  // Floor
  if (minFloor) {
    if (listing.floor < minFloor) return false;
  }
  if (maxFloor) {
    if (listing.floor > maxFloor) return false;
  }
  if (minFloors) {
    if (listing.floors < minFloors) return false;
  }
  if (maxFloors) {
    if (listing.floors > maxFloors) return false;
  }
  if (
    !checkFloorIsSuitable(
      noFirstFloor,
      noLastFloor,
      onlyFirstFloor,
      onlyLastFloor,
      listing
    )
  )
    return false;

  // Year built
  if (minYearBuilt) {
    if (listing.yearBuilt < minYearBuilt) return false;
  }
  if (maxYearBuilt) {
    if (listing.yearBuilt > maxYearBuilt) return false;
  }

  // Building material
  if (buildingMaterials && buildingMaterials.length > 0) {
    if (!buildingMaterials.includes(listing.buildingMaterial)) return false;
  }

  // Location
  if (regions && regions.length) {
    const region = head(regions);
    if (not(propEq(region, "region", listing))) return false;
  }
  if (locations && locations.length) {
    if (!locations.includes(listing.location)) return false;
  }
  if (subLocations && subLocations.length) {
    if (listing.subLocation && !subLocations.includes(listing.subLocation))
      return false;
  }
  if (countries && countries.length) {
    if (!countries.includes(listing.country)) return false;
  }
  if (details && details.length) {
    if (details.some((detail) => !listing.details.includes(detail)))
      return false;
  }
  if (specifics && specifics.length) {
    if (specifics.some((specific) => !listing.specifics.includes(specific)))
      return false;
  }
  if (keywords && keywords.length) {
    if (
      !keywords.some((keyword) =>
        listing.toString().toLowerCase().includes(keyword.toLowerCase())
      )
    )
      return false;
  }

  return true;
};

export const log =
  (component) =>
  ([...msgs]) =>
    console.log(`[${component}]: `, ...msgs);

export const calculateNotaryTax = (price, currency) => {
  // Convert to BGN if EURO
  if (currency === "EURO") price = price * 2;

  let tax = 0;

  switch (true) {
    case price <= 100:
      tax = 30;
      break;
    case price <= 1000:
      tax = 30 + (1.5 / 100) * (price - 100);
      break;
    case price <= 10000:
      tax = 43.5 + (1.3 / 100) * (price - 1000);
      break;
    case price <= 50000:
      tax = 160.5 + (0.8 / 100) * (price - 10000);
      break;
    case price <= 100000:
      tax = 480.5 + (0.5 / 100) * (price - 50000);
      break;
    case price <= 500000:
      tax = 730.5 + (0.2 / 100) * (price - 100000);
      break;
    default:
      const actual = 1530.5 + (0.1 / 100) * (price - 500000);
      tax = actual > 6000 ? 6000 : actual;
      break;
  }

  // Add VAT
  tax += 0.2 * tax;
  return currency === "EURO" ? parseFloat(tax / 2) : parseFloat(tax);
};

export const priceToCurrencyString = (
  price,
  currency,
  listingType = "sale",
  translation
) => {
  const isEuro = currency === "EURO";

  let numberFormatOptions = {
    currency: isEuro ? "EUR" : "BGN",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  };

  if (isEuro) numberFormatOptions.style = "currency";

  const formatter = new Intl.NumberFormat(undefined, numberFormatOptions);

  return isEuro
    ? `${formatter.format(price)}${
        equals(listingType, "rent") ? translation("perMonth") : ""
      }`
    : `${formatter.format(price).toString().replace("BGN ", "")}${
        equals(listingType, "rent")
          ? translation("bgnPerMonth")
          : translation("bgn")
      }`;
};

export const checkErrorAndAction = async ({
  errorMessage,
  humanizedMessage,
  userStore,
  loginStore,
  navigate,
  translation,
}) => {
  if (errorMessage.toLowerCase().includes("duplicate key")) {
    console.error(errorMessage);
    if (includes("email", errorMessage))
      toast(translation("duplicateEmail"), {
        type: "error",
        position: "bottom-right",
      });
  } else if (includes("Грешна парола", humanizedMessage || "")) {
    console.error(errorMessage);
    toast(`${translation("incorrectCreds")}!`, {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes("Потребителят не беше наемрен.", humanizedMessage || "")
  ) {
    console.error(errorMessage);
    toast(`${translation("incorrectCreds")}!`, {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes("Вече сте влезли в акаунта си!", humanizedMessage || "")
  ) {
    console.error(errorMessage);
    toast(`${translation("incorrectCreds")}!`, {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes("Participant is already in the chatroom!", humanizedMessage || "")
  ) {
    console.error(errorMessage);
    toast(translation("userAlreadyInRoom"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes("No such chatroom was found!", humanizedMessage || "") ||
    includes("The chatroom specified doesn't exist!", humanizedMessage || "")
  ) {
    console.error(errorMessage);
    toast(translation("chatroomNotFound"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes(
      "The participant you're attempting to remove doesn't exist!",
      humanizedMessage || ""
    )
  ) {
    console.error(errorMessage);
    toast(translation("userNotFound"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (includes("Обявата не беше намерена!", humanizedMessage || "")) {
    console.error(errorMessage);
    toast(translation("listingNotFound"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes(
      "Потребителят, който се опитвате да премахнете не е в стаята!",
      humanizedMessage || ""
    )
  ) {
    console.error(errorMessage);
    toast(translation("userNotInRoom"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes(
      "The administrator you're trying to remove does not exist!",
      humanizedMessage || ""
    )
  ) {
    console.error(errorMessage);
    toast(translation("userNotFound"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes("The name provided is too short!", humanizedMessage || "")
  ) {
    console.error(errorMessage);
    toast(translation("nameTooShort"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes("No such property type was found!", humanizedMessage || "")
  ) {
    console.error(errorMessage);
    toast(translation("pTypeNotFound"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes("Моля изберете наследник за обявите си!", humanizedMessage || "")
  ) {
    console.error(errorMessage);
    toast(translation("Моля изберете наследник за обявите си!"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes("No user found with that email.", humanizedMessage || "")
  ) {
    console.error(errorMessage);
    toast(translation("userNotFound"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes(
      "Моля изберете друг наследник за обявите си!",
      humanizedMessage || ""
    )
  ) {
    console.error(errorMessage);
    toast(translation("Моля изберете друг наследник за обявите си!"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (includes("No such region was found!", humanizedMessage || "")) {
    console.error(errorMessage);
    toast(translation("regionNotFound"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (
    includes(
      "Търсенето, което се опитвате да промените не беше намерено!",
      humanizedMessage || ""
    )
  ) {
    console.error(errorMessage);
    toast(translation("searchNotFound"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (includes("401", errorMessage)) {
    const lastKnownUrl = `${window.location.pathname}${window.location.search}`;
    const { data, errors } = await client.mutate({
      mutation: LOGOUT_MUTATION,
    });
    if (and(data, not(isEmpty(prop("_id", userStore))))) {
      userStore.logout();
      loginStore.setLoginState({ loading: false, loginHasRun: false });
      loginStore.setLastKnownUrl(lastKnownUrl);
      navigate("/вход");
    }

    if (errors) console.error(errors);
  } else if (includes("403", errorMessage)) {
    console.error(errorMessage);
    if (humanizedMessage)
      toast(humanizedMessage, {
        type: "error",
        position: "bottom-right",
      });
    else
      toast(translation("notEnoughPerms"), {
        type: "error",
        position: "bottom-right",
      });
  } else if (includes("500", errorMessage)) {
    console.error(errorMessage);
    toast(translation("internal500"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (includes("504", errorMessage)) {
    console.error(errorMessage);
    toast(translation("timeoutError"), {
      type: "error",
      position: "bottom-right",
    });
  } else if (includes("is required", errorMessage)) {
    console.error(errorMessage);
    toast(translation("fillInMissingFields"), {
      type: "error",
      position: "bottom-right",
    });
  } else {
    console.error(errorMessage);
    toast(errorMessage, {
      type: "error",
      position: "bottom-right",
    });
  }
};

export function getLocaleDateString(dateString, resolvedLanguage) {
  const date = new Date(dateString);

  const months = {
    bg: [
      "Януари",
      "Февруари",
      "Март",
      "Април",
      "Май",
      "Юни",
      "Юли",
      "Август",
      "Септември",
      "Октомври",
      "Ноември",
      "Декември",
    ],
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    gr: [
      "Ιανουάριος",
      "Φεβρουάριος",
      "Μάρτιος",
      "Απρίλιος",
      "Μάιος",
      "Ιούνιος",
      "Ιούλιος",
      "Αύγουστος",
      "Σεπτέμβριος",
      "Οκτώβριος",
      "Νοέμβριος",
      "Δεκέμβριος",
    ],
  };

  const fromThisYear = new Date().getFullYear() === date.getFullYear();
  return `${date.getDate()} ${months[resolvedLanguage][date.getMonth()]} ${
    fromThisYear ? "" : date.getFullYear()
  }`;
}

const keyRaw = process.env.REACT_APP_ENC_KEY;
const ivRaw = process.env.REACT_APP_ENC_IV;

export const encrypt = (value, isPlainText = true) => {
  if (or(isNil(value), isEmpty(value))) return value;

  const encrypted = AES.encrypt(
    isPlainText ? value : JSON.stringify(value),
    keyRaw,
    {
      iv: ivRaw,
      mode: mode.CBC,
      padding: pad.Pkcs7,
    }
  ).toString();

  return encrypted;
};

export const decrypt = (encrypted, isPlainText = true) => {
  if (or(isNil(encrypted), isEmpty(encrypted))) return encrypted;

  let decrypted = AES.decrypt(encrypted, keyRaw, {
    ivRaw,
    mode: mode.CBC,
    padding: pad.Pkcs7,
  }).toString(enc.Utf8);

  return isPlainText ? decrypted : JSON.parse(decrypted);
};

export const checkAndReportFormValidityById = (formId) => {
  try {
    const form = document.getElementById(formId);
    if (form) {
      const formIsValid = form?.checkValidity();
      if (not(formIsValid)) {
        form.reportValidity();
        return false;
      }
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const isNilOrEmpty = (value) => or(isNil(value), isEmpty(value));

export const copyTextToClipboard = async (text) => {
  if (typeof ClipboardItem && navigator.clipboard.write) {
    // NOTE: Safari locks down the clipboard API to only work when triggered
    //   by a direct user interaction. You can't use it async in a promise.
    //   But! You can wrap the promise in a ClipboardItem, and give that to
    //   the clipboard API.
    //   Found this on https://developer.apple.com/forums/thread/691873
    const item = new ClipboardItem({
      "text/plain": new Blob([text], { type: "text/plain" }),
    });
    await navigator.clipboard.write([item]);
  } else {
    // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
    //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
    //   Good news is that other than Safari, Firefox does not care about
    //   Clipboard API being used async in a Promise.
    await navigator.clipboard.writeText(text);
  }
};

export const preloadThumbnails = async (mediaItems, setLoadingThumbnails) => {
  const promises = await take(3, mediaItems).map(({ url, type }) => {
    if (equals(type, "image"))
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.src = url;
        img.onload = resolve();
        img.onerror = reject();
      });
  });

  await Promise.all(promises);

  setLoadingThumbnails(false);
};
